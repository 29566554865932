input[type='file'] {
  display: none;
}

.fileInput {
  padding: 0.6em;
  background-color: #1976D2;
  display: flex;
  border-radius: 8px;
  justify-content: center;
  color: white;
  font-weight: 600;
  font-size: 16px;
  max-width: 200px;
}

.fileInput:hover {
  cursor: grab;
}