:root {
  --color-blue: #3284ff;
  --color-black: #000;
  --color-grey: #585858;
  --color-green: #395c04;
}

.container {
  display: grid;
  grid-template-columns: 2fr 1fr;
  width: 100%;
  padding: 20px;
}

.data-section {
  display: grid;
  flex-wrap: wrap;
  grid-template-columns: repeat(1, 1fr);
  gap: 15px;
  /* background: red; */
}

.entity{
  display: flex;
  flex-direction: column;
}

.entity-cards{
  display: flex;
  gap: 10px;
}

.card {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 300px;
  border: 1px solid var(--color-blue);
  min-height: 300px;
}
.upper {
  height: 80%;
  background: var(--color-blue);
  padding: 20px;
}

.card-grey {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 300px;
  border: 1px solid var(--color-grey);
  min-height: 300px;
}

.upper-grey {
  height: 80%;
  background: var(--color-grey);
  padding: 20px;
}

.card-green {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 300px;
  border: 1px solid var(--color-green);
  min-height: 300px;
}

.upper-green {
  height: 80%;
  background: var(--color-green);
  padding: 20px;
}

.card-black {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 300px;
  border: 1px solid var(--color-black);
  min-height: 300px;
}

.upper-black {
  height: 80%;
  background: var(--color-black);
  padding: 20px;
}

.lower {
  height: 20%;
  padding: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.data {
  color: white;
  font-size: larger;
}

.data > span {
  font-size: 36px;
}

.property-list {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
}
