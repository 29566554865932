.amenities {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  gap: 15px;
}

.amenity {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  padding: 5px;
  box-shadow: 4px 2px 2px grey;
}

.amenity:hover {
  background: whitesmoke;
}

.mapcontainer {
  width: 800px;
  height: 400px;
}

.select__map {
  display: flex;
  width: 300px;
  padding: 10px;
  justify-content: center;
  border: 1px solid black;
  border-radius: 5px;
}

.select__map:focus,
.select__map:hover,
.select__map:active {
  border: 1px solid rgb(0, 153, 255);
}

.lat__lng {
  margin: 10px 0;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.mapmodal {
  width: auto;
}

.modal__div {
  display: flex;
  position: relative;
}

.marker {
  position: absolute;
  left: calc(50% - 20px);
  top: calc(50% - 40px);
}

.reject_reason_modal {
  display: flex;
  padding: 20px;
  gap: 10px;
  flex-direction: column;
}

.reject_reason {
  font-size: 24px;
  font-weight: 10px;
  color: red;
}

.reject_reason_q {
  font-size: 18px;
  font-style: italic;
  margin-bottom: 40px;
}

.reject_reason_h {
  color: red;
  font-size: small;
  font-style: italic;
}

.ab {
  display: flex;
  gap: 10px;
}

.sub {
  padding: 10px;
  background: rgb(0, 153, 255);
  color: white;
  font-weight: 600;
  cursor: pointer;
  border-radius: 10px;
}

.can {
  padding: 10px;
  background: rgba(0, 0, 0, 0.1);
  color: black;
  font-weight: 600;
  cursor: pointer;
  border-radius: 10px;

}

@media only screen and (max-width: 1236px) {
  .amenities {
    grid-template-columns: repeat(4, 1fr);
  }
}

@media only screen and (max-width: 1024px) {
  .amenities {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media only screen and (max-width: 720px) {
  .amenities {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media only screen and (max-width: 600px) {
  .amenities {
    grid-template-columns: repeat(1, 1fr);
  }
}
